import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import { useInView } from "react-intersection-observer"
import { useViewportScroll, useTransform, useAnimation } from "framer-motion"

import { useRefScrollProgress } from "../../hooks/UseRefScrollProgress"
import Heading, { Type } from "../../UI/Heading"

import {
  Section,
  Content,
  Image,
  Description,
  ImageContainer,
  RedMineral,
  WhiteMineral,
  PinkMineral,
} from "./styles"

import mineralImage from "../../../assets/conflicts/minerals/minerals.png"
import mineralOne from "../../../assets/conflicts/minerals/mineral1.png"
import mineralTwo from "../../../assets/conflicts/minerals/mineral2.png"
import mineralThree from "../../../assets/conflicts/minerals/mineral3.png"

const MineralParallax = ({ title, description }) => {
  const [contentRef, inContentView] = useInView()
  const controls = useAnimation()
  const { ref, start, end } = useRefScrollProgress()
  const { scrollYProgress } = useViewportScroll()
  const addStart = -0.12
  const addEnd = 0.2
  const translateUp = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    ["0", "-20vw"]
  )
  const translateDown = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    ["0", "20vw"]
  )
  const translateLeft = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    ["0", "-20vw"]
  )
  const translateRight = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    ["0", "5vw"]
  )
  const translateFarLeft = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    ["0", "-40vw"]
  )
  const rotateRight = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    [0, 360]
  )
  const rotateLeft = useTransform(
    scrollYProgress,
    [start + addStart, end + addEnd],
    [0, -360]
  )

  useLayoutEffect(() => {
    if (inContentView) {
      controls.start("visible")
    }
  }, [controls, inContentView])

  const variants = {
    visible: { scale: 1, opacity: 1 },
    hidden: { scale: 1.2, opacity: 0 },
  }

  return (
    <Section>
      <Content
        ref={contentRef}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.8 }}
        variants={variants}
      >
        <Heading type={Type.h2}>{title}</Heading>
        <Description>{ReactHtmlParser(description)}</Description>
      </Content>
      <ImageContainer ref={ref}>
        <RedMineral
          style={{
            x: translateLeft,
            translateY: translateUp,
            rotate: rotateLeft,
          }}
          src={mineralOne}
          alt="red mineral"
        />
        <WhiteMineral
          style={{
            x: translateRight,
            translateY: translateUp,
            rotate: rotateRight,
          }}
          src={mineralTwo}
          alt="white mineral"
        />
        <PinkMineral
          style={{
            x: translateFarLeft,
            translateY: translateDown,
            rotate: rotateLeft,
          }}
          src={mineralThree}
          alt="white mineral"
        />
        <Image src={mineralImage} alt="mineral" />
      </ImageContainer>
    </Section>
  )
}

MineralParallax.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default MineralParallax
